export const actionIds = {
    // Fetch App Data
    SOLR_SEARCH_FETCH: "SOLR_SEARCH_FETCH",
    CASE_LAW_SEARCH_FETCH: "CASE_LAW_SEARCH_FETCH",
    SOLR_REGULATIONS_FETCH: "SOLR_REGULATIONS_FETCH",
    SOLR_QUICK_FETCH: "SOLR_QUICK_FETCH",
    SOLR_RELATED_FETCH: "SOLR_RELATED_FETCH",
    SOLR_SEARCH_SUCCESS: "SOLR_SEARCH_SUCCESS",
    CASE_LAW_SEARCH_SUCCESS: "CASE_LAW_SEARCH_SUCCESS",
    SOLR_REGULATIONS_SUCCESS: "SOLR_REGULATIONS_SUCCESS",
    SOLR_QUICK_SUCCESS: "SOLR_QUICK_SUCCESS",
    SOLR_RELATED_SUCCESS: "SOLR_RELATED_SUCCESS",
    AI_SEARCH: "AI_SEARCH",
    SET_AI_RESULT: "SET_AI_RESULT",
    SET_AI_RESULT_CASE_LAW: "SET_AI_RESULT_CASE_LAW",
    CLEAR_AI_RESULT: "CLEAR_AI_RESULT",
    CLEAR_AI_RESULT_CASE_LAW: "CLEAR_AI_RESULT_CASE_LAW",
    MERGE_AI_RESULT: "MERGE_AI_RESULT",
    MERGE_AI_RESULT_CASE_LAW: "MERGE_AI_RESULT_CASE_LAW",
    UPDATE_AI_FEEDBACK: "UPDATE_AI_FEEDBACK",
    USER_ACTION: "USER_ACTION",
    UPDATE_USER_ACTION: "UPDATE_USER_ACTION",
    UPDATE_BASIC_USER_ACTION: "UPDATE_BASIC_USER_ACTION",
    GET_USER_ACTION: "GET_USER_ACTION",
    GET_USER_SUCCESS: "GET_USER_SUCCESS",
    SIGNIN_ACTION: "SIGNIN_ACTION",
    SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
    PASSWORD_SET_ACTION: "PASSWORD_SET_ACTION",
    PASSWORD_RESET_ACTION: "PASSWORD_RESET_ACTION",
    LOGIN_ERROR: "LOGIN_ERROR",
    LOGOUT: "LOGOUT",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    PASSWORD_ERROR: "PASSWORD_ERROR",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    LOADING: "LOADING",
    NOT_LOADING: "NOT_LOADING",
    SHOW_ERROR: "SHOW_ERROR",
    CLEAR_SEARCH_CACHE: "CLEAR_SEARCH_CACHE",
    GET_DOCUMENT_REFERENCES: "GET_DOCUMENT_REFERENCES",
    VECTORIZE_DOCUMENT_REFERENCE: "VECTORIZE_DOCUMENT_REFERENCE",
    GET_ADMIN_DASHBOARD_DATA: "GET_ADMIN_DASHBOARD_DATA",
    INDEX_DOCUMENT: "INDEX_DOCUMENT",
    FULL_INDEX: "FULL_INDEX",
    GET_SYSTEM_SETTINGS: "GET_SYSTEM_SETTINGS",
    UPDATE_SYSTEM_SETTINGS: "UPDATE_SYSTEM_SETTINGS",
    SEARCH_REGULATIONS: "SEARCH_REGULATIONS",
    SEARCH_GUIDANCE: "SEARCH_GUIDANCE",
};

/**
 * type: type of action
 * payload: payload of action
 * normalized (optional): indicate a action must be normalized before handle it (add)
 * removeKeys (optional): indicate a action must be normalized before handle it (remove)
 * setkeys (optional): indicate a action must be normalized before handle it (update)
 */
export interface BaseAction {
    type: string;
    payload: any;
    onSuccess: Function | null
    onFail: Function | null
}
