import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { get } from "lodash";
import { HttpResponse, HttpStatus } from "../interfaces/HttpResponse";
import { PublicResource } from "../interfaces/PublicResource";

const API_URL: string = process.env.REACT_APP_SERVER_DOMAIN || "https://endpointnotfound";
interface IAxiosHttpResponseWrapper<T> {
  data: T;
  errors: unknown[];
}

export const objectToQuery = (obj: any) => {
  return (
    "?" +
    Object.keys(obj)
      .map((key) => key + "=" + obj[key])
      .join("&")
  );
};

export const objectToQueryExcludeUndefined = (obj: any) => {
  return (
    "?" +
    Object.keys(obj)
      .map((key) => {
        if (obj[key]) {
          return key + "=" + obj[key];
        }
        return null;
      })
      .filter((item) => item !== null)
      .join("&")
  );
};

export default class HttpClient {
  private url: string;

  private publicResources: PublicResource[] = [];

  private currentResource: string;

  private axios = axios.create();

  constructor() {
    this.url = `${API_URL}`;
    this.currentResource = "";
    this.axios.interceptors.request.use(this.requestInterceptor.bind(this) as any);
    this.axios.interceptors.response.use(this.onResponse, this.onResponseError);
  }

  public setPublicResources(resources: PublicResource[]): void {
    this.publicResources = resources;
  }

  public addAuthAndCookieHeaders(options?: AxiosRequestConfig): AxiosRequestConfig {
    // Ensure options and headers are defined
    options = options || {};
    options.headers = options.headers || {};
  
    // Check if the cookie is available in localStorage
    const cookie = window.localStorage.getItem('cookie');
    if (cookie) {
      options.headers['Cookie'] = cookie; // Add the cookie to the headers
    }
  
    // Check if the JWT is available in localStorage
    const jwt = window.localStorage.getItem('jwt');
    if (jwt) {
      options.headers['Authorization'] = `Bearer ${jwt}`; // Add the JWT to the Authorization header
    }
  
    return options;
  }

  public async get<Data>(resource: string, params: any, options?: AxiosRequestConfig): Promise<HttpResponse<any>> {

    options = this.addAuthAndCookieHeaders(options);

    this.currentResource = resource;
    let endpoint = `${this.url}/${resource}`;
    params.cookie = window.localStorage.cookie
    endpoint += objectToQuery(params);
    try {
      const response = await this.axios.get<{ data: Data; errors: unknown[] }>(endpoint, options);
      return {
        status: response.status as HttpStatus,
        data: response.data,
        errors: [],
      };
    } catch (e: any) {
      const standardizedError = handleAxiosError(e as AxiosError);
      console.log(`Error ${standardizedError.status}: ${standardizedError.message}`);
      throw new Error(standardizedError.message);
    }
  }

  public async post<Data>(resource?: string, data?: unknown, options?: AxiosRequestConfig): Promise<HttpResponse<any>> {

    options = this.addAuthAndCookieHeaders(options);
    this.currentResource = resource ?? "";
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
  
    try {
      const response = await this.axios.post<IAxiosHttpResponseWrapper<Data>>(endpoint, data, options);
      return {
        status: response.status,
        data: response.data,
        errors: response.data.errors,
      };
    } catch (e: any) {
      const standardizedError = handleAxiosError(e as AxiosError);
      console.log(`Error ${standardizedError.status}: ${standardizedError.message}`);
      throw new Error(standardizedError.message);
    }
  }


  public async getFile<Data>(resource?: string): Promise<HttpResponse<any>> {
    this.currentResource = resource ?? "";
    const endpoint = resource ? `${this.url}/${resource}` : this.url;

    try {
      const options: AxiosRequestConfig = { responseType: "blob" };
      const response = await this.axios.get<{ data: Data; errors: unknown[] }>(endpoint, options);
      return {
        status: response.status as HttpStatus,
        data: response.data,
        errors: [],
      };
    } catch (e: any) {
      const error = e;
      if (error.response?.data.error) {
        throw new Error(error.response?.data.error);
      } else if (error.response.status === 401) {
        throw new Error("Unauthorized resource access.");
      } else {
        throw new Error("An unexpected error ocurried.");
      }
    }
  }

  public async uploadFiles<Data>(resource?: string, data?: unknown, options?: AxiosRequestConfig): Promise<HttpResponse<any>> {
    this.currentResource = resource ?? "";
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
    try {
      const options: AxiosRequestConfig = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      const response = await this.axios.post<IAxiosHttpResponseWrapper<Data>>(endpoint, data, options);

      return {
        status: response.status as HttpStatus,
        data: response.data,
        errors: [],
      };
    } catch (e) {
      console.log(e);
      throw new Error("file upload error");
    }
  }

  public async postFormData<Data>(resource?: string, data?: unknown, options?: AxiosRequestConfig): Promise<HttpResponse<any>> {
    this.currentResource = resource ?? "";
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
    try {
      const response = await this.axios.post<IAxiosHttpResponseWrapper<Data>>(endpoint, data, options);
      return {
        status: response.status,
        data: response.data,
        errors: response.data.errors,
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      throw new Error(error.response?.data.error);
    }
  }


  public async patch<Data>(resource?: string, data?: unknown, options?: AxiosRequestConfig): Promise<HttpResponse<Data>> {
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
    try {
      const response = await this.axios.patch<IAxiosHttpResponseWrapper<Data>>(endpoint, data, options);
      return {
        status: response.status,
        data: response.data.data,
        errors: response.data.errors,
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      throw new Error(error.response?.data.error);
    }
  }

  public async put<Data>(resource?: string, data?: unknown, options?: AxiosRequestConfig): Promise<HttpResponse<any>> {
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
    try {
      const response = await this.axios.put<IAxiosHttpResponseWrapper<Data>>(endpoint, data, options);
      return {
        status: response.status,
        data: response.data,
        errors: response.data.errors,
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      throw new Error(error.response?.data.error);
    }
  }

  public async delete<Data>(resource?: string, options?: AxiosRequestConfig): Promise<HttpResponse<any>> {
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
    try {
      const response = await this.axios.delete<IAxiosHttpResponseWrapper<Data>>(endpoint, options);
      return {
        status: response.status,
        data: response.data,
        errors: response.data.errors,
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      throw new Error(error.response?.data.error);
    }
  }

  private async onResponse(response: AxiosResponse) {
    // console.info(`[response] [${JSON.stringify(response)}]`);
    // console.log('response.status', response.status)
    return response;
  }

  private async onResponseError(error: AxiosError) {
    // console.error(`[response error] [${JSON.stringify(error)}]`);
    // console.log('error.code', error.response)
    // if (error?.response?.status === 401){
    //   window.location.href = '/'
    // }
    return Promise.reject(error);
  }

  private async requestInterceptor(config: AxiosRequestConfig) {
    if (this.publicResources.length > 0) {
      const isResourcePublic = !!this.publicResources.find(
        (r) => r.resource === this.currentResource && r.method === config.method,
      );
      if (isResourcePublic) {
        return config;
      }
    }
    const token = localStorage.getItem("jwt");
    config.headers = { Authorization: `bearer ${token}` };
    return config;
  }

  
}
interface StandardErrorResponse {
  status: number;
  message: string;
  data?: any;
  errors?: any[];
}

export function handleAxiosError(error: any): StandardErrorResponse {
  let status = 500; // Default to 500 in case no response status is available
  let message = "An unexpected error occurred.";
  let data = null;
  let errors = [];

  if (error.response) {
    status = error.response.status;

    if (error.response?.data?.error){
        message = error.response.data.error
        data = error.response.data
        errors = error.response.data.errors || [error.response?.data?.error]
    }
    else{
        message = error.response.data?.message || message
        data = error.response.data
        errors = error.response.data?.errors || []
    }
    // Server responded with a status other than 2xx
  } else if (error.request) {
    // Request was made but no response was received
    status = 408; // Request timeout
    message = "No response received from the server. Please try again.";
  } else {
    // Something else happened while setting up the request
    message = error.message || message;
  }

  return {
    status,
    message,
    data,
    errors,
  };
}