import { IgetUser } from "../interfaces/getUser";
import { IpasswordResetPost } from "../interfaces/passwordResetPost";
import { IpasswordSetPost } from "../interfaces/passwordSetPost";
import { IsigninPost } from "../interfaces/signinPost";
import { IupdateBasicUser } from "../interfaces/updateBasicUser";
import { IupdateUser } from "../interfaces/updateUser";
import { IuserPost } from "../interfaces/userPost";
import { actionIds, BaseAction } from "./actionIds";
import { genericAction } from "./genericAction";

/**
 * registration
 * @param userData
 */
export const userAction: (userAction: IuserPost, onSuccess: any, onFail: any) => BaseAction = (userAction, onSuccess, onFail) => {
    const payload = {
        userAction,
    };
    return {
        type: actionIds.USER_ACTION,
        payload,
        onSuccess: onSuccess || null,
        onFail: onFail || null
    };
};

export const signinAction = genericAction(actionIds.SIGNIN_ACTION);
export const passwordSetAction = genericAction (actionIds.PASSWORD_SET_ACTION);
export const passwordResetAction = genericAction (actionIds.PASSWORD_RESET_ACTION);

export const getUserInfo: (getUserInfo: IgetUser, onSuccess?: any, onFail?: any) => BaseAction = (getUserInfo, onSuccess, onFail) => {
    const payload = {
        getUserInfo,
    };
    return {
        type: actionIds.GET_USER_ACTION,
        payload,
        onSuccess: onSuccess || null,
        onFail: onFail || null
    };
};

export const updateUserInfo: (updateUserInfo: IupdateUser, onSuccess?: any, onFail?: any) => BaseAction = (updateUserInfo, onSuccess, onFail) => {
    const payload = {
        updateUserInfo,
    };
    return {
        type: actionIds.UPDATE_USER_ACTION,
        payload,
        onSuccess: onSuccess || null,
        onFail: onFail || null
    };
};

export const updateBasicUserInfo: (updateBasicUserInfo: any, onSuccess?: any, onFail?: any) => BaseAction = (updateBasicUserInfo, onSuccess, onFail) => {
    const payload = {
        updateBasicUserInfo,
    };
    return {
        type: actionIds.UPDATE_BASIC_USER_ACTION,
        payload,
        onSuccess: onSuccess || null,
        onFail: onFail || null
    };
};

export const logoutAction = genericAction(actionIds.LOGOUT);
